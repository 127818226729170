import React, { Component } from "react"
import { graphql, Link } from "gatsby"

import Head from "./../components/Head/Head"
import BaseLayout from "./../Layouts/BaseLayout"
import Colors from "./../Theme/theme"

class BlogPage extends Component {
  constructor(props) {
    super(props)

    this.customData = props.data
  }

  // d-flex justify-content-center
  render() {
    return (
      <BaseLayout>
        <Head pageTitle="Blog" />
        <br />
        <br />
        <br />

        <p
          className="text-center mt-5 pt-4 mx-5"
          style={{ fontSize: "2.5em", fontWeight: "300" }}
        >
          Notes & Tips to Self
        </p>

        <div className="row wrap mx-lg-4 d-flex justify-content-center">
          {this.customData.allMarkdownRemark.edges.map(edge => (
            <div
              key={edge.node.id}
              className="my-1 px-4 pt-4 pb-3 col-10 col-sm-6 col-md-4 mx-1"
              style={{
                boxShadow: "1px 1px 5px 0px grey",
                maxWidth: "340px",
              }}
            >
              <Link
                style={{ color: Colors.primary }}
                to={`/blog/${edge.node.id}`}
                key={edge.node.id}
                partiallyActive={true}
              >
                <p style={{ fontSize: "1.3em" }}>
                  {edge.node.frontmatter.title}
                </p>
              </Link>

              <p className="text-secondary">
                {edge.node.frontmatter.description}
              </p>

              <hr />
              <p className="small mb-0 text-secondary">
                Published - {edge.node.frontmatter.date}
              </p>

              <p className="py-2 d-flex flex-wrap">
                {edge.node.frontmatter.tags.map(tag => (
                  <span
                    style={{
                      border: `1px solid ${Colors.accent2}`,
                      color: Colors.accent2,
                      borderRadius: "5px",
                    }}
                    className="small px-2 py-1 mr-1 mt-1"
                    key={tag}
                  >
                    {tag}&nbsp;
                  </span>
                ))}
              </p>
            </div>
          ))}
        </div>
        <br />
        <br />
        <br />
      </BaseLayout>
    )
  }
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            description
            tags
            title
            date(formatString: "DD MMMM YYYY")
          }
        }
      }
    }
  }
`

export default BlogPage
